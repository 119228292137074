<script>
import Page2D from '@/components/Page2D'
import { useI18n } from 'vue-i18n'

export default {
  name: 'WaitConfirmPage',
  
  components: {
    Page2D
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
};
</script>

<template>
  <Page2D :title="t('waitConfirmPage.title')">
    {{t('waitConfirmPage.message')}}
  </Page2D>
</template>
