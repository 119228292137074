<script>
/** TextField component requires because Vuetify 3 still doesn't has it */
import DropdownIcon from '@/assets/imgs/icons/dropdown-arror.svg'

export default {
  name: 'DropdownField',
  components: {
    DropdownIcon
  },
  props: {
    modelValue: {
      type: String,
      default: '',
      required: true
    },
    maxHeight: {
      type: Number,
    },
    placeholder: {
      type: String,
      default: ''
    },
    /** options set key => label */
    options: {
      type: Object,
      default: () => ({})
    },
    /** options icons set key => icon */
    icons: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:modelValue'
  ],
  data() {
    return {
      val: this.modelValue,
      open: false,
      focusable: true
    }
  },
  watch: {
    modelValue(next) {
      this.val = next
    },
    val(next) {
      this.$emit('update:modelValue', next)
    }
  },
  computed: {
    optionsList() {
      return Object.keys(this.options).map(key => ({
        value: key,
        label: this.options[key]
      }))
        .sort((a,b) => {
          return a.value === b.value
            ? 0
            : a.value > b.value
              ? 1
              : -1
        })
    },
    hasIcons() {
      return !!Object.keys(this.icons || {}).length
    },
    inputText() {
      if (this.val === '') return ''
      return this.options[this.val]
    },
    inputIsDisabled() {
      return this.disabled || this.open || !this.focusable
    }
  },
  methods: {
    clickOutside() {
      this.open = false
      this.focusable = true
    },
    selectOption(val) {
      this.val = val
    },
    async toggleDropdown() {
      if (this.disabled)
        return
      this.open = !this.open
      this.focusable = !this.open
    }
  }
}
</script>

<template>
<div class="field-wrapper">
  <input
    :disabled="inputIsDisabled"
    :placeholder="placeholder"
    :class="{
      'error': error,
      'with-icon': hasIcons && val !== ''
    }"
    :value="inputText"
    @focus="toggleDropdown"
  />
  <DropdownIcon :class="{open: open}" class="toggle-button"/>
  <div @click="toggleDropdown" v-click-outside="clickOutside" class="click-holder"/>
  <component class="selected-icon" v-if="hasIcons && val !== ''" :is="icons[val]"/>
  <div v-if="open" class="dropdown-wrapper overflow-y-auto custom-scrollbar" :style="{'max-height': maxHeight + 'px'}">
    <div class="dropdown-item" v-for="opt, i of optionsList" :key="i">
      <button @click="selectOption(opt.value)" class="dropdown-button pa-2">
        <component class="item-icon" v-if="hasIcons" :is="icons[opt.value]"/>
        {{opt.label}}
      </button>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  padding: 14px;
  background: #fff;
  box-shadow: #B1B1B158 0px 6px 20px;
  cursor: pointer;

  &.with-icon {
    padding-left: 60px;
  }

  &.error {
    color: rgb(var(--v-theme-error)) !important;
    border: 1px solid rgb(var(--v-theme-error)) !important;
  }
}

.dropdown-button:hover{
  color: rgb(var(--v-theme-fb-navy));
  background-color: rgb(var(--v-theme-admin-grey-2));
}

input:focus {
  border: 0 !important;
}

.field-wrapper {
  position: relative;

  .click-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  svg.toggle-button {
    position: absolute;
    top: 18px;
    //transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
    fill: #ccc;

    &.open {
      fill: var(--v-theme-grey-2d);
    }
  }

  svg.selected-icon, svg.item-icon {
    width: 30px;
    height: 30px;
  }

  svg.selected-icon {
    position: absolute;
    top: 11px;
    left: 20px;
  }

  .dropdown-wrapper {
    position: absolute;
    bottom: 0px;
    left: 0px;
    transform: translate(0, calc(100% + 2px));
    width: 100%;
    padding: 10px;
    // padding-top: 0px;
    box-shadow: #B1B1B158 0px 6px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 10;
    background: #fff;

    .dropdown-item {
      padding: 2px;

      .dropdown-button {
        width: 100%;
        text-align: left;
        color: rgb(var(--v-theme-grey-2d));

        &:hover {
          color: #000;
        }

        svg {
          position: relative;
          top: 9px;
        }
      }
    }
  }
}
</style>
