<script>
import Page2D from '@/components/Page2D'

export default {
  name: 'BannedPage',
  
  components: {
    Page2D
  }
};
</script>

<template>
  <Page2D :title="$t('rejectPage.title')">
    {{$t('rejectPage.message')}}
  </Page2D>
</template>
