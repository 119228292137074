<script>
/** TextField component requires because Vuetify 3 still doesn't has it */
import EyeIcon from '@/assets/imgs/icons/eye.svg'

export default {
  name: 'TextField',
  components: {
    EyeIcon
  },
  props: {
    modelValue: {
      // type: String || Number,
      default: '',
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator: value => ['text', 'password', 'number'].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'on',
      validator: value => ['on', 'off'].includes(value)
    },
    error: {
      type: Boolean,
      default: false
    },
    help: {
      type: String,
      default: ''
    }
  },
  emits: [
    'update:modelValue',
    'onEnter'
  ],
  data() {
    return {
      val: this.modelValue,
      open: false
    }
  },
  watch: {
    modelValue(next) {
      this.val = next
    },
    val(next) {
      if (this.type === 'number')
        this.$emit('update:modelValue', parseInt(next) || 0)
      else
        this.$emit('update:modelValue', next)
    }
  },
  computed: {
    getType() {
      return this.type === 'password' && !this.open ? 'password' : 'text'
    }
  }
}
</script>

<template>
<div class="field-wrapper">
  <input
    :type="getType"
    :disabled="disabled"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    :class="{
      'error': error
    }"
    v-model="val"
    @keyup.enter="$emit('onEnter')"
  />
  <EyeIcon :class="{open: open}" @click="open = !open" v-if="type === 'password'"/>
  <div v-if="help !== ''" class="mt-1 font-size-14 text-fb-navy">
    {{help}}
  </div>
</div>
</template>

<style scoped lang="scss">
input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  padding: 14px;
  background: #fff;
  box-shadow: #B1B1B158 0px 6px 20px;

  &.error {
    color: rgb(var(--v-theme-error)) !important;
    border: 1px solid rgb(var(--v-theme-error)) !important;
  }
}

input:focus {
  border: 0 !important;
}

.field-wrapper {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
    fill: #ccc;

    &.open {
      fill: var(--v-theme-grey-2d);
    }
  }
}
</style>
