<script>
import { useI18n } from 'vue-i18n'
import Page2D from '@/components/Page2D'
import AlertIcon from '@/assets/imgs/icons/alert.svg'

export default {
  name: 'ErrorPage',
  
  components: {
    Page2D,
    AlertIcon
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<template>
  <Page2D :title="t('errorPage.title')">
    <div class="mt-6 mb-11">
      <div class="text-error">
        <AlertIcon class="mx-2 alert-icon"/> {{t($store.state.ui.error || 'error.unknown')}}
      </div>
      <div
        v-if="$store.state.ui.errorLink"
        class="mt-11"
      >
        <a
          class="text-link-2d font-size-15"
          :href="$store.state.ui.errorLink"
        >{{t('error.labels.readMore')}}</a>
      </div>
    </div>
  </Page2D>
</template>

<style scoped>
.alert-icon {
  fill: rgb(var(--v-theme-error));
}
</style>
