<script>
import Page2D from '@/components/Page2D'
import { useI18n } from 'vue-i18n'

export default {
  name: 'NotSupportedPage',
  components: {
    Page2D
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<template>
  <Page2D :title="t(`notSupportedPage.title.${$route.params.reason}`)">
    <p :innerHTML="t(`notSupportedPage.text.${$route.params.reason}`)"/>
  </Page2D>
</template>
