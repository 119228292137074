<script>
import { useI18n } from 'vue-i18n'
import Page2D from '@/components/Page2D'
import TextField from '@/components/fields/Text'
import AlertIcon from '@/assets/imgs/icons/alert.svg'

export default {
  name: 'LoginPage',
  
  components: {
    Page2D,
    TextField,
    AlertIcon
  },
  data: () => ({
    email: '',
    password: '',
    loading: false,
    state: 'input',
    error: ''
  }),
  methods: {
    async login() {
      this.loading = true
      this.$store.commit('ui/setBusy', true)
      this.$store.commit('setTouch', true)
      const result = await this.$store.dispatch('user/login', {
        email: this.email,
        password: this.password
      })
      this.loading = false
      this.$store.commit('ui/setBusy', false)
      if (result.success) {
        this.state = 'success'
        //wait for user profile to be loaded
        await this.$store.dispatch('user/init/next')
        if (!this.$can('admin')) {
          // non-admin user redirect to 3d campus, admins choose next option
          // console.log('!ADMIN!! -> goto 3d')
          this.$router.push({ name: '3DPage', params: { scene: 'campus' }})
        }
        return
      }
      this.state = 'fail'
      this.error = result.error
      setTimeout(() => {
        this.state = 'input'
        this.error = ''
      }, 2500)
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<template>
<div>
  <Page2D v-if="$can('admin')" :title="t('loginPage.admin.title')">
    <div>
      <router-link :to="{name: '3DPage', params:{scene:'campus'}}">{{t('loginPage.admin.campus')}}</router-link>
    <div>
    </div>
      <router-link :to="{name: 'AdminUsers'}">{{t('loginPage.admin.admin')}}</router-link>
    </div>
  </Page2D>
  <Page2D v-if="!$can('admin')" :title="t('loginPage.title')">
    <div v-if="error !== ''" class="error-message-2d rounded-5 pa-1 mb-1">
      <AlertIcon class="mx-2 alert-icon"/> {{t(error)}}
    </div>
    <TextField
      :disabled="loading || $store.state.ui.busy"
      v-model="email"
      class="mb-5"
      :class="{
        'mt-15': error === '',
        'mt-1': error !== ''
      }"
      :error="error !== ''"
      :placeholder="t('loginPage.fields.email')"
    />
    <TextField
      :disabled="loading || $store.state.ui.busy"
      v-model="password"
      :error="error !== ''"
      @keyup.enter="login()"
      :placeholder="t('loginPage.fields.password')"
      type="password"
    />
    <div class="mt-6 mb-11">
      <router-link class="text-link-2d font-size-15" :to="{ name: 'ForgotPasswordPage' }">
        {{t('loginPage.buttons.forgot')}}
      </router-link>
    </div>
    <div>
      <v-btn
        :disabled="email.length === 0 || password.length === 0"
        :loading="loading"
        @click="login()"
        id="btn-login"
        class="big-button-2d"
      >
        <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
        <span v-else>{{t('loginPage.buttons.login')}}</span>
      </v-btn>
      <span class="mx-5 text-link-2d font-size-15">{{t('loginPage.labels.or')}}</span>
      <router-link class="text-link-2d font-size-15" :to="{ name: 'RegisterPage' }">{{t('loginPage.buttons.register')}}</router-link>
    </div>
  </Page2D>
</div>
</template>
