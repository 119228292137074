<script>
import Page2D from '@/components/Page2D'
import dayjs from 'dayjs'
import Logo from '@/assets/imgs/countdown-page-logo.svg'

import dayz from '@/dayz'
import { isDayz } from "@/dayz";

export default {
  name: 'CountdownPage',
  
  components: {
    Page2D,
    Logo
  },
  created() {
    this.timer = setInterval(() => {
      const now = dayjs().valueOf()
      const difference = dayz.valueOf() - now
      this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
      if (isDayz()) {
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
          this.$router.push('/3d/campus')
        }
      }
    },1000)
  },
  data: () => ({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds:0,
    timer: null
  }),
  computed: {
    dys() {
      let str = `${this.days}`
      if (str.length === 1)
        str = '0' + str
      return str
    },
    hrs() {
      let str = `${this.hours}`
      if (str.length === 1)
        str = '0' + str
      return str
    },
    mnts() {
      let str = `${this.minutes}`
      if (str.length === 1)
        str = '0' + str
      return str
    },
    scnds() {
      let str = `${this.seconds}`
      if (str.length === 1)
        str = '0' + str
      return str
    },
  }
}
</script>

<template>
  <Page2D :title="$t('countdownPage.title')">
    <div class="countdown-page d-flex flex-column">
      <div class="py-10 px-5 text-center">
        <Logo class="countdown-page-logo"/>
      </div>
      <div class="d-flex flex-column justify-center flex-grow-1">
        <div class="countdown-page-timer d-flex flex-wrap justify-center align-items-center text-center">
          <div class="w-100 font-size-24 text-center">{{ $t('countdownPage.title') }}</div>
          <div class="timer-box">
          {{dys}}<br/><span class="font-size-24">Days</span>
          </div>
          <span class="timer-dots">:</span>
          <div class="timer-box">
          {{hrs}}<br/><span class="font-size-24">hours</span>
          </div>
          <span class="timer-dots">:</span>
          <div class="timer-box">
          {{mnts}}<br/><span class="font-size-24">minutes</span>
          </div>
          <span class="timer-dots">:</span>
          <div class="timer-box">
          {{scnds}}<br/><span class="font-size-24">Seconds</span>
          </div>
        </div>
      </div>
    </div>
  </Page2D>
</template>

<style lang="scss" scoped>
.countdown-page-logo{
  path:first-child{
    fill:red;
  }
}
.alert-icon {
  fill: rgb(var(--v-theme-error));
}
.countdown-page{
  position: fixed;
  background-color: #f2f0e5;
  top:0;
  left:0;
  z-index: 10000;
  width:100%;
  height:100%;
}
.countdown-page-timer{
  font-size: 100px;
  color: #154d62;
}
.timer-box{
  width: 170px;
  span{
    display: block;
  }
}
.timer-dots{
  align-self: start;
  margin-top: -8px;
  font-weight:normal;
}
</style>
