<script>
import DropdownField from '@/components/fields/Dropdown'

import { UserRole } from '@/lib/enums'

export default {
  name: 'RoleDropdownField',
  components: {
    DropdownField
  },
  props: {
    modelValue: {
      type: Number,
      default: UserRole.Guest
    },
    maxHeight: {
      type: Number,
    },
    filter: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() { return {
    val: UserRole[this.modelValue] || ''
  }},
  computed: {
    options() {
      const ret = {
        [UserRole[UserRole.Guest]]: this.$t(`roles.${UserRole.Guest}`),
        [UserRole[UserRole.Root]]: this.$t(`roles.${UserRole.Root}`),
        [UserRole[UserRole.Trainer]]: this.$t(`roles.${UserRole.Trainer}`),
        [UserRole[UserRole.CommunityManager]]: this.$t(`roles.${UserRole.CommunityManager}`),
        [UserRole[UserRole.BrandInfluencer]]: this.$t(`roles.${UserRole.BrandInfluencer}`),
        [UserRole[UserRole.RetailSalesAssociate]]: this.$t(`roles.${UserRole.RetailSalesAssociate}`),
        [UserRole[UserRole.FacebookEmployee]]: this.$t(`roles.${UserRole.FacebookEmployee}`),
        [UserRole[UserRole.CorporatePartner]]: this.$t(`roles.${UserRole.CorporatePartner}`),
      }
      this.filter.forEach(out => {
        delete ret[ UserRole[out] ]
      })
      return ret
    }
  },
  watch: {
    modelValue(next) {
      this.val = UserRole[next]
    },
    val(next) {
      this.$emit('update:modelValue', UserRole[next])
    }
  }
}
</script>

<template>
<DropdownField
  :disabled="disabled"
  :placeholder="$t('roles.placeholder')"
  v-model="val"
  :options="options"
  :maxHeight="maxHeight"
/>
</template>
