<script>
import { Locale } from '@/lib/enums.js'
export default {
  name: 'TermsAndConditions',
  emits: ['close'],
  computed: {
    src() {
      return `/terms-and-conditions/${Locale[this.$store.state.ui.locale] || 'en'}.html`
    }
  }
}
</script>

<template>
<div class="tac" @click="$emit('close')">
  <div @click.stop class="tac-overlay-window bg-white elevation-2 border rounded-lg pa-5 d-flex flex-column">
    <iframe class="flex-grow-1 overflow-y-auto custom-scrollbar" :src="src"/>
    <div class="text-center pt-5">
      <v-btn
        color="primary"
        @click="$emit('close')"
      >
        {{$t('buttons.close')}}
      </v-btn>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>

.tac {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.4);
  z-index: 40;

  &-overlay-window {
    position: absolute;
    top: 100px;
    bottom: 100px;
    left: 200px;
    right: 200px;
    iframe{
      border:1px solid rgb(var(--v-theme-grey-2d));
    }
  }
}
</style>
