<script>
import Page2D from '@/components/Page2D'
import TextField from '@/components/fields/Text'
import DropdownField from '@/components/fields/Dropdown'
import RoleDropdown from '@/components/fields/RoleDropdown'
//import JobPositionSelector from '@/components/fields/JobPositionSelector'
import TaC from '@/components/TermsAndConditions'
import AlertIcon from '@/assets/imgs/icons/alert.svg'
import { useI18n } from 'vue-i18n'
import waitFor from '@/utils/waitFor'
import * as Enums from '@/lib/enums'
import { UserRole } from '@/lib/enums'

import { isDayz } from '@/dayz'

import FlagUSA from '@/assets/imgs/icons/flags/usa.svg'
import FlagFrance from '@/assets/imgs/icons/flags/france.svg'
import FlagSpain from '@/assets/imgs/icons/flags/spain.svg'
import FlagUK from '@/assets/imgs/icons/flags/uk.svg'

export default {
  name: 'RegisterPage',
  
  components: {
    Page2D,
    TextField,
    //JobPositionSelector,
    DropdownField,
    RoleDropdown,
    AlertIcon,
    TaC,
  },
  data: () => ({
    name: '',
    email: '',
    password: '',
    password2: '',
    loading: false,
    company: '',
    strCountry: '',
    country: 0,
    jobPosition: undefined,
    error: '',
    agreementDone: false,
    state: 'input',
    tac: false,
    companyOther: false
  }),
  methods: {
    async register() {
      this.loading = true
      this.$store.commit('ui/setBusy', true)
      const result = await this.$store.dispatch('user/register', {
        fullname: this.name,
        password: this.password,
        email: this.email,
        country: this.country,
        company: this.company,
        jobPosition: this.jobPosition,
        agree: this.agreementDone
      })
      this.loading = false
      this.$store.commit('ui/setBusy', false)
      if (!result.success) {
        this.error = result.error
        this.state = 'fail'
        await waitFor(5000)
        this.error = ''
        this.state = 'input'
        return
      }
      this.state = 'success'
      await waitFor(1000)
      console.log('register redirect', result, this.$store.state.user.data)
      if (isDayz())
        this.$router.push({name: 'AccountUnderReviewPage'})
      else
        this.$router.push({name: 'CountdownPage'})
    }
  },
  computed: {
    rolesFilter(){
      return [UserRole.Root, UserRole.Guest]
    },
    formValid() {
      return this.name.length 
        && this.email.length
        && this.password.length
        && this.password2.length
        && this.password === this.password2
        && this.country !== ''
        && this.company !== ''
        && typeof this.jobPosition === 'number'
        && this.agreementDone
    },
    countriesOptions() {
      return ['US','FR','ES','GB'].reduce((acc,key) => {
        acc[Enums.Country[key]] = this.t(`countries.${key}`)
        return acc
      }, {})
    },
    countriesFlags() {
      return {
        [Enums.Country.US]: FlagUSA,
        [Enums.Country.FR]: FlagFrance,
        [Enums.Country.ES]: FlagSpain,
        [Enums.Country.GB]: FlagUK
      }
    },
    companies() {
      const ret = [
        'Best Buy',
        'Best Buy Canada',
        'Target',
        'Walmart',
        'Boulanger',
        'FNAC',
        'MSH MediaMarkt',
        'Dixons',
        'Selfridge',
        'Harrods',
      ].reduce((acc,el) => {
        acc[el] = el
        return acc
      }, {})
      ret['other'] = this.$t('registerPage.fields.companyOther')
      return ret
    }
  },
  watch: {
    name() {
      this.state = 'input'
    },
    email() {
      this.state = 'input'
    },
    password() {
      this.state = 'input'
      if (this.password !== this.password2)
        this.error = 'registerPage.errors.passwordsNotMatch'
      else
        this.error = ''
    },
    password2() {
      this.state = 'input'
      if (this.password !== this.password2)
        this.error = 'registerPage.errors.passwordsNotMatch'
      else
        this.error = ''
    },
    strCountry() {
      this.country = parseInt(this.strCountry)
      // this.retailer = ''
    },
    company(next) {
      if (this.companyOther)
        return
      if (next.startsWith('other')) {
        this.companyOther = true
        this.company = ''
      }
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<template>
  <TaC v-if="tac" @close="tac = false"/>
  <Page2D :title="t('registerPage.title')">
    <div :class="{
      'mb-11': error === '',
      'mb-1': error !== ''
    }" class="font-size-15 text-grey-2d">{{t('registerPage.labels.welcome')}}</div>
    <div v-if="error !== ''" id="error-message" class="error-message-2d rounded-5 pa-1 mb-1">
      <AlertIcon class="mx-2 alert-icon"/> {{t(error)}}
    </div>
    <TextField
      :disabled="loading || $store.state.ui.busy"
      v-model="name"
      autocomplete="off"
      class="mb-5"
      :placeholder="t('registerPage.fields.name')"
    />
    <TextField
      :disabled="loading || $store.state.ui.busy"
      v-model="email"
      autocomplete="off"
      class="mb-5"
      :placeholder="t('registerPage.fields.email')"
    />
    <TextField
      :disabled="loading || $store.state.ui.busy"
      v-model="password"
      autocomplete="off"
      class="mb-5"
      :placeholder="t('registerPage.fields.password')"
      type="password"
    />
    <TextField
      :disabled="loading || $store.state.ui.busy"
      v-model="password2"
      autocomplete="off"
      class="mb-5"
      :placeholder="t('registerPage.fields.password2')"
      type="password"
    />
    <DropdownField
      :disabled="loading || $store.state.ui.busy"
      :options="countriesOptions"
      :icons="countriesFlags"
      v-model="strCountry"
      class="mb-5"
      :placeholder="t('registerPage.fields.country')"
    />
    <DropdownField
      v-if="!companyOther"
      :disabled="loading || $store.state.ui.busy"
      :options="companies"
      v-model="company"
      :maxHeight="150"
      class="mb-5"
      :placeholder="t('registerPage.fields.company')"
    />
    <TextField
      v-else
      :disabled="loading || $store.state.ui.busy"
      v-model="company"
      autocomplete="off"
      class="mb-5"
      :placeholder="t('registerPage.fields.company')"
    />
    <RoleDropdown
      class="mb-5"
      v-model="jobPosition"
      :disabled="loading"
      :maxHeight="150"
      :placeholder="t('registerPage.fields.jobPosition')"
      :filter="rolesFilter"
    />
    <!--
    <JobPositionSelector
      v-model="jobPosition"
      :disabled="loading || $store.state.ui.busy"
      :placeholder="t('registerPage.fields.jobPosition')"
      class="mb-5"
    />
    -->
    <!--
    <DropdownField
      :disabled="loading || $store.state.ui.busy || strCountry === ''"
      :options="retailersOptions"
      v-model="retailer"
      class="mb-5"
      :placeholder="t('registerPage.fields.retailer')"
    />
    -->
    <div class="agreement-field mb-5">
      <input id="agreement-checkbox" type="checkbox" :checked="agreementDone" @click="agreementDone = !agreementDone"/>
      <label for="agreement-checkbox" class="ml-4">
        {{t('registerPage.fields.agree')}}
        <button class="btn-link" @click="tac = !tac">
          {{t('registerPage.fields.termsAndConditions')}}
        </button>
      </label>
    </div>
    <div class="my-10">
      <v-btn
        :disabled="loading || $store.state.ui.busy ||!formValid"
        :loading="loading"
        @click="register()"
        id="btn-register"
        class="big-button-2d"
        :class="{
          'btn-success': state === 'success',
          'btn-danger': state === 'fail'
        }"
      >
        <AlertIcon v-if="state === 'fail'"/>
        <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
        <v-icon v-if="state === 'success'" size="sm">mdi-check-circle-outline</v-icon>
        <span class="text-bold" v-if="state === 'input' && !loading">{{t('registerPage.buttons.register')}}</span>
      </v-btn>
    </div>
    <div class="text-link-2d font-size-15">
      <span class="mr-2">{{t('registerPage.labels.hasAccount')}}</span>
      <router-link class="text-link-2d text-bold" :to="{ name: 'LoginPage' }">{{t('registerPage.buttons.signIn')}}</router-link>
    </div>
  </Page2D>
</template>

<style scoped>
.btn-danger {
  background: rgb(var(--v-theme-error)) !important;
}
.btn-success {
  background: rgb(var(--v-theme-success)) !important;
  color: #fff;
}
.btn-link {
  text-decoration: underline;
}
</style>
