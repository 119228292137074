<script>
import { useI18n } from 'vue-i18n'
import Page2D from '@/components/Page2D'
import TextField from '@/components/fields/Text'
import isValidEmail from '@/utils/isValidEmail'
import AlertIcon from '@/assets/imgs/icons/alert.svg'

export default {
  name: 'ForgotPasswordPage',
  
  components: {
    Page2D,
    AlertIcon,
    TextField
  },
  data: () => ({
    email: '',
    success: null,
    fail: null,
    loading: false
  }),
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    isValid() {
      return isValidEmail(this.email)
    }
  },
  methods: {
    async restore() {
      this.loading = true
      this.$store.commit('ui/setBusy', true)
      const result = await this.$store.dispatch('user/restorePassword', {
        email: this.email
      })
      this.loading = false
      this.$store.commit('ui/setBusy', false)
      if (result.success) {
        this.success = true
      } else {
        this.fail = true
      }
      setTimeout(() => {
        this.fail = false
        this.success = false
      }, 2500)
    }
  }
};
</script>

<template>
  <Page2D :title="t('restorePasswordPage.title')">
    <div v-if="success" id="success-message" class="success-message-2d rounded-5 pa-1 mb-1">
      <AlertIcon class="mx-2 alert-icon"/> {{t('restorePasswordPage.labels.success')}}
    </div>
    <div v-if="fail" class="error-message-2d rounded-5 pa-1 mb-1">
      <AlertIcon class="mx-2 alert-icon"/> {{t('restorePasswordPage.labels.fail')}}
    </div>
    <TextField
      :disabled="loading || $store.state.ui.busy"
      v-model="email"
      class="mb-5"
      :class="{
        'mt-15': !fail && !success,
        'mt-1': fail || success
      }"
      :error="fail"
      :placeholder="t('restorePasswordPage.fields.email')"
    />
    <div class="my-10">
      <v-btn
        :disabled="loading || $store.state.ui.busy || !isValid"
        :loading="loading"
        @click="restore()"
        id="btn-restore"
        class="big-button-2d"
        :class="{
          'btn-success': success,
          'btn-danger': fail
        }"
      >
        <AlertIcon v-if="fail"/>
        <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
        <v-icon v-if="success" size="sm">mdi-check-circle-outline</v-icon>
        <span class="text-bold" v-if="!success && !fail && !loading">{{t('restorePasswordPage.buttons.restore')}}</span>
      </v-btn>
      <span class="mx-5 text-link-2d font-size-15">{{t('restorePasswordPage.labels.or')}}</span>
      <router-link class="text-link-2d font-size-15" :to="{ name: 'LoginPage' }">{{t('restorePasswordPage.buttons.signIn')}}</router-link>
    </div>
 
  </Page2D>
</template>

<style scoped>
.btn-danger {
  background: rgb(var(--v-theme-error)) !important;
}
.btn-success {
  background: rgb(var(--v-theme-success)) !important;
  color: #fff;
}
</style>
